import {ActivityIndicator, ScrollView, TouchableOpacity} from "react-native";
import Flex from "@components/Views/Flex";
import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import {formatDateDefaultYear} from "@src/helpers";
import Tag from "@components/General/Tag";
import Button from "@components/Buttons/Button";
import React, {useEffect, useState} from "react";
import productionApi from "@api/productionApi";
import FloatingBottom from "@components/Views/FloatingBottom";

export default function ReviewDocs({offerId, changeStep, finishDocs, employment}) {

    const [loading, setLoading] = useState<boolean>(true);
    const [documentsToSign, setDocumentsToSign] = useState<any>([]);
    const [documentsToUpload, setDocumentsToUpload] = useState<any>([]);
    const [documentsFromI9, setDocumentsFromI9] = useState<any>([]);

    useEffect(() => {
        getDocuments()
    }, [])

    const getDocuments = async () => {
        setLoading(true);

        let response = await productionApi.getOfferDocuments(offerId);

        setDocumentsToSign(response.data.filter((doc: any) => doc.attributes.type == "signature"));
        setDocumentsToUpload(response.data.filter((doc: any) => doc.attributes.type == "upload"));

        if (response.meta.i9_documents != null) {
            setDocumentsFromI9(Object.values(response.meta.i9_documents));
        }

        setLoading(false);
    };

    const submitDocuments = async () => {
        setLoading(true);
        let response = await productionApi.postConfirmDocuments(offerId, {});
        finishDocs()
        setLoading(false);
    }

    return (
        <>
            <Flex className="flex-1 mt-5" verticalAlign="between">
                <ScrollView className="flex-1 px-5">
                    <Flex className="flex-1">

                        {loading && <ActivityIndicator className="mt-5"/>}

                        {!loading && documentsToSign.length > 0 &&

                            <Flex className="mb-8">
                                <FlexRow className="mb-3 pb-2 border-denim-20 border-b-0.5" verticalAlign="end" align="between">
                                    <Text type="xs-medium" color="navy-100">Signed Documents</Text>
                                    <TouchableOpacity onPress={() => changeStep("sign")}>
                                        <Text type="xs-semibold" color="denim-50">Edit Docs</Text>
                                    </TouchableOpacity>
                                </FlexRow>

                                {documentsToSign.map((document: any) =>
                                    <FlexRow
                                        className="py-3 mb-2 border-b-0.5 border-denim-20"
                                        verticalAlign="center" align="between">
                                        <Flex className="flex-1">
                                            <Text type="xs-medium">{document.attributes.title}</Text>
                                            <Text type="xs-medium" color="denim-50">Last
                                                Updated: {formatDateDefaultYear(document.attributes.last_updated_at) ?? "N/A"}</Text>
                                        </Flex>
                                        <Flex>
                                            <Tag type={document.attributes.is_completed ? "signed" : "not signed"}/>
                                        </Flex>
                                    </FlexRow>
                                )}
                            </Flex>
                        }

                        {!loading && (documentsToUpload.length > 0 || documentsFromI9.length > 0) &&
                            <Flex className="mb-8">
                                <FlexRow className="mb-3 pb-2 border-denim-20 border-b-0.5" verticalAlign="end" align="between">
                                    <Text type="xs-medium" color="navy-100">Verification Documents</Text>
                                    <TouchableOpacity onPress={() => changeStep("upload")}>
                                        <Text type="xs-semibold" color="denim-50">Edit Docs</Text>
                                    </TouchableOpacity>
                                </FlexRow>

                                {documentsToUpload.map((document: any) =>
                                    <FlexRow
                                        className="py-3 mb-2 border-b-0.5 border-denim-20"
                                        align="between"
                                        verticalAlign="center">
                                        <Flex className="flex-grow">
                                            <Text type="xs-medium">{document.attributes.title}</Text>
                                            <Text type="xs-medium" color="denim-50">
                                                Last Updated: {formatDateDefaultYear(document.attributes.last_updated_at) ?? "N/A"}
                                            </Text>
                                        </Flex>
                                        <Tag type={document.attributes.is_completed ? "uploaded" : "missing"}/>
                                    </FlexRow>
                                )}

                                {documentsFromI9.map((document: any) =>
                                    <FlexRow
                                        className="py-3 mb-2 border-b-0.5 border-denim-20"
                                        verticalAlign="center" align="between">
                                        <Flex className="flex-1">
                                            <Text type="xs-medium">{document.title}</Text>
                                        </Flex>
                                        <Flex>
                                            <Tag type="uploaded"/>
                                        </Flex>
                                    </FlexRow>
                                )}
                            </Flex>}

                    </Flex>
                </ScrollView>
                <FloatingBottom>
                    <Button useFullColor={true} value="Submit All" color="navy-light-100" className="mt-2" onPress={() => submitDocuments()} loading={loading}/>
                </FloatingBottom>
            </Flex>
        </>
    )
}