import React, {useEffect, useState} from "react";
import {Linking, TouchableOpacity} from "react-native";
import {MaskedTextInput} from "react-native-mask-text";
import TextInput from "@components/Forms/TextInput";

import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import Button from "@components/Buttons/Button";
import FlexRow from "@components/Views/FlexRow";
import {userLoginType} from "@interfaces/UserInterface";
import userApi from "@api/userApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {setGlobal} from "reactn";
import Header from "@components/Views/Header";
import Checkbox from 'expo-checkbox';
import AddressInput from "@components/Forms/AddressInput";
import SecondaryButton from "@components/Buttons/SecondaryButton";
import {useNavigation} from "@react-navigation/native";
import {navigationProps} from "@interfaces/RoutesInterface";
import {transformTailwindToHex} from "@src/helpers";
import AlertCircle from "@assets/icons/alert-circle.svg";

export default function GettingStarted() {
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [homeAddress, setHomeAddress] = useState<object | string | null>(null);
    const [extra, setExtra] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [ssn, setSSN] = useState<string>("");
    const [ein, setEin] = useState<string>("");
    const [isChecked, setChecked] = useState(false);
    const [step, setStep] = useState(1);
    const [employmentType, setEmploymentType] = useState("w2");
    const [loanOut, setLoanOut] = useState(0);
    const [offerId, setOfferId] = useState<string | null>(null);
    const [productionId, setProductionId] = useState<string | null>(null);
    const [productionName, setProductionName] = useState<string | null>(null);

    const navigation = useNavigation<navigationProps>();

    useEffect(() => {
        getSignupDataFromLocalStorage();
    }, []);

    const getSignupDataFromLocalStorage = async () => {
        try {
            let signupData = await AsyncStorage.getItem('signupData');
            if (signupData !== null) {
                let data = JSON.parse(signupData);
                setName(data.first_name);
                setLastName(data.last_name);
                setPhoneNumber(data.phone_number);
                setEmail(data.email);
                setLoanOut(data.loan_out);
            }

            let signupOfferId = await AsyncStorage.getItem('signupOfferId');
            if (signupOfferId !== null) {
                setOfferId(signupOfferId);
            }

            let signupProductionId = await AsyncStorage.getItem('signupProductionId');
            if (signupProductionId !== null) {
                setProductionId(signupProductionId);
            }

            let signupProductionEmployment = await AsyncStorage.getItem('signupProductionEmployment');
            if (signupProductionEmployment !== null) {
                setEmploymentType(signupProductionEmployment);
            }

            let signupProductionName = await AsyncStorage.getItem('signupProductionName');
            if (signupProductionName !== null) {
                setProductionName(signupProductionName);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const singup = async () => {
        setLoading(true);

        try {
            let response: userLoginType = await userApi.signup({
                offer_id: offerId,
                email: email,
                first_name: name,
                last_name: lastName,
                phone_number: phoneNumber,
                password: password,
                confirm_password: confirmPassword,
                address: homeAddress,
                extra: extra,
                ein: ein,
                ssn: ssn,
            });

            await AsyncStorage.removeItem('signupData');
            await AsyncStorage.removeItem('signupOfferId');
            await AsyncStorage.removeItem('signupProductionId');
            await AsyncStorage.removeItem('signupProductionName');
            await AsyncStorage.removeItem('signupProductionEmployment');

            await AsyncStorage.setItem('redirectTo', JSON.stringify({
                page: "OfferOnboarding",
                params: {
                    offerId: offerId,
                    productionId: productionId,
                    productionName: productionName,
                    employment: employmentType
                }
            }));

            await AsyncStorage.setItem('access_token', response.meta.access_token);
            await AsyncStorage.setItem('user', JSON.stringify(response.data.attributes));
            await setGlobal({
                user: response.data.attributes,
                access_token: response.meta.access_token
            });
        } catch (e) {}

        setLoading(false);
    };

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Header title="Getting Started" showBack={false}/>

            <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
                <Text type="sm-semibold" color="white">Setup Your FilmUp Account</Text>
                <Text type="xs-normal" className="opacity-70 pt-2" color="white">Confirm the email address and password that you would like to use to access your FilmUp account.</Text>
            </Flex>

            <Flex className="flex-1 bg-white" verticalAlign="between">
                {step == 1 && <Flex className="flex-1 px-5 gap-y-2 pb-5 pt-5">

                    {(password.length < 6 || password != confirmPassword) &&
                        <FlexRow verticalAlign="center" align="start" className="p-2 bg-coral-10 rounded-xl border border-coral-100 mb-5">
                            <AlertCircle width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
                            <Text type="xs-medium" color="coral-100" className="ml-2">Password needs to have more than 6 characters and 'Confirm Password' needs to match it.</Text>
                        </FlexRow>
                    }

                    <TextInput label="Email" onChange={setEmail} value={email}/>
                    <TextInput label="Password" password={true} onChange={setPassword} value={password}/>
                    <TextInput label="Confirm Password" password={true} onChange={setConfirmPassword} value={confirmPassword}/>

                    <FlexRow verticalAlign="center" className="pt-2">
                        <Checkbox value={isChecked} onValueChange={setChecked}/>
                        <Text type="xs-bold" className="ml-3">I accept FilmUp's
                            <TouchableOpacity onPress={() => Linking.openURL("https://dashboard.filmup.co/service-terms")}>
                                <Text type="xs-bold" color="navy-light-100"> Terms of Use </Text>
                            </TouchableOpacity>
                            <Text type="xs-bold">and</Text>
                            <TouchableOpacity onPress={() => Linking.openURL("https://dashboard.filmup.co/privacy-policy")}>
                                <Text type="xs-bold" color="navy-light-100"> Privacy Policy </Text>
                            </TouchableOpacity>
                        </Text>
                    </FlexRow>

                </Flex>}

                {step == 2 && <Flex className="flex-1 px-5 gap-y-2 pb-5 pt-5">
                    <TextInput label="First Name" onChange={setName} value={name}/>
                    <TextInput label="Last Name" onChange={setLastName} value={lastName}/>
                    <TextInput label="Phone Number" onChange={setPhoneNumber} value={phoneNumber}/>
                    <AddressInput label="test" value={homeAddress} onChange={setHomeAddress}/>
                    <TextInput label="Home Address (Line 2)" onChange={setExtra} value={extra} required={false}/>
                    <MaskedTextInput
                        placeholder="SSN"
                        keyboardType='numeric'
                        className="w-full p-3 rounded-xl text-xs mb-3 border border-denim-20"
                        mask="999-99-9999"
                        onChangeText={(text, rawText) => {
                            setSSN(text)
                        }}
                        value={ssn}
                    />

                    {employmentType != 'w2' &&
                        <MaskedTextInput
                            secureTextEntry={true}
                            placeholder="EIN"
                            keyboardType='numeric'
                            className="w-full p-3 rounded-xl text-xs mb-3 border border-denim-20"
                            mask="99-9999999"
                            onChangeText={(text, rawText) => {
                                setEin(text)
                            }}
                            value={ein}
                        />
                    }

                </Flex>}


                {step == 1 && <Flex className="rounded-3xl px-8 py-3 bg-white shadow-lg gap-y-2 pb-5">
                    <Button
                        useFullColor={true}
                        value="Next"
                        className="flex-grow"
                        onPress={() => setStep(2)}
                        disabled={!isChecked || password == "" || password.length < 6 || password != confirmPassword
                            || confirmPassword == "" || email == ""}
                    />
                </Flex>}

                {step == 2 && <Flex className="rounded-3xl px-8 py-3 bg-white shadow-lg gap-y-2 pb-5">
                    <SecondaryButton
                        value="Back"
                        onPress={() => setStep(1)}
                    />
                    <Button
                        useFullColor={true}
                        value="Sign Up"
                        className="mt-2"
                        onPress={() => singup()}
                        loading={loading}
                        disabled={!isChecked || name == "" || lastName == "" || phoneNumber == "" || homeAddress == null || ssn == ""}
                    />
                </Flex>}
            </Flex>
        </Flex>
    )
        ;
}
