import React, {useEffect, useRef, useState} from "react";

import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import productionApi from "@api/productionApi";
import FlexRow from "@components/Views/FlexRow";
import Button from "@components/Buttons/Button";
import {StackScreenProps} from "@react-navigation/stack";
import {NonAuthStackParamList} from "@src/routes";
import {ScrollView, TouchableOpacity, View} from "react-native";
import ContentTable from "@components/General/ContentTable";
import ContentList from "@components/General/ContentList";
import RBSheet from "react-native-raw-bottom-sheet";
import moment from "moment";
import {formatDateDefault, formatDateDefaultYear, msToHMS, transformTailwindToHex} from "@src/helpers";
import CheckRounded from "@assets/icons/check-rounded.svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {translatedKitFee, translatedRateAmount} from "@components/General/Rates";
import Content from "@components/General/Content";
import Header from "@components/Views/Header";
import SecondaryButton from "@components/Buttons/SecondaryButton";
import {getGlobal} from "reactn";
import {DeviceType} from "expo-device";
import FloatingBottom from "@components/Views/FloatingBottom";

require('moment-countdown');

type OfferSummaryProps = StackScreenProps<NonAuthStackParamList, 'OfferSummary'>;
export default function OfferSummary({navigation, route}: OfferSummaryProps) {

    const [loading, setLoading] = useState<boolean>(true);
    const [offer, setOffer] = useState<any>(null);
    const [status, setStatus] = useState<any>(null);
    const [timeleft, setTimeLeft] = useState<string>("00:00:00");
    const [declineType, setDeclineType] = useState<number>(0);
    const [acceptType, setAcceptType] = useState<number>(0);
    const [directDeposit, setDirectDeposit] = useState<number>(1);
    const [viewDetailsTitle, setViewDetailsTitle] = useState<string>('');
    const [viewDetailsContent, setViewDetailsContent] = useState<string>('');

    const refRBSheet = useRef();
    const refRBSheet2 = useRef();
    const refRBSheet3 = useRef();
    const refRBSheet4 = useRef();
    const refRBSheet5 = useRef();

    useEffect(() => {
        getOffer();
    }, [navigation, route]);

    useEffect(() => {
        updateTimeLeft()
    }, [offer]);

    const getOffer = async () => {
        setLoading(true);

        const response = await productionApi.getPublicOffer(route.params.id);

        if (response.data.has_login && response.data.status != "expired" && (navigation.getState().routeNames).includes("Login")) {
            navigation.navigate("Login");
            return;
        }

        if (response.data.status == "accepted") {
            navigation.navigate("HowItWorks");
        }

        setOffer(response.data);
        setStatus(response.data.status);
        setLoading(false);
    };

    const updateTimeLeft = async () => {
        if (offer != null) {
            if (offer.expiration == null) {
                setTimeLeft("-");

            } else {

                let expirationTime = moment(offer.expiration);

                if (moment().isAfter(expirationTime)) {
                    setTimeLeft("00:00:00");
                    return;
                }

                const time = expirationTime.countdown();
                setTimeLeft(msToHMS(time.value))

                setTimeout(() => updateTimeLeft(), 1000)
            }
        }
    };
    const rejectOffer = async () => {
        refRBSheet.current.close()

        setLoading(true);
        let response = await productionApi.rejectPublicOffer(route.params.id, declineType == 1);
        getOffer();
    };

    const acceptOffer = async () => {
        refRBSheet5.current.close()

        let response = await productionApi.acceptPublicOffer(route.params.id, {loan_out: acceptType, direct_deposit: directDeposit});

        response.data.signup.loan_out = acceptType;

        await AsyncStorage.setItem("signupData", JSON.stringify(response.data.signup));
        await AsyncStorage.setItem("signupOfferId", route.params.id);
        await AsyncStorage.setItem("signupProductionId", offer.production_id);
        await AsyncStorage.setItem("signupProductionName", offer.production_name);
        await AsyncStorage.setItem("signupProductionEmployment", offer.employment);
        await AsyncStorage.setItem("signupProductionLoanOut", (offer.employment == "loan_out" || acceptType == 1) ? "true" : "false");

        navigation.navigate("HowItWorks");
    };

    if (offer == null) {
        return null;
    }

    return (
        <Flex className="bg-white flex flex-1" verticalAlign="between">
            <Header title="Offer Details" showBack={false}/>
            <ScrollView>
                <Flex className="mx-7">
                    <Flex className="gap-y-1 pt-4">
                        <FlexRow verticalAlign="center" align="between">
                            <Text type="sm-semibold" color="navy-light-100">{offer.role}</Text>
                            {status != "rejected" && status != "expired" && timeleft != "-" && <Text type="xs-semibold" color="coral-100">Expires in {timeleft}</Text>}
                            {status != "rejected" && status != "expired" && timeleft == "-" && <Text type="xs-semibold" color="coral-100">Does not Expire</Text>}
                            {status == "rejected" && <Text type="xs-semibold" color="coral-100">Declined</Text>}
                            {status == "expired" && <Text type="xs-semibold" color="coral-100">Expired</Text>}
                        </FlexRow>

                        <Text type="xs-medium" color="denim-50">Offered By: {offer.company_name}</Text>
                        <View className="border-b border-navy-light-100/10 pt-3 -mx-7"/>
                    </Flex>


                    <ContentTable className="mt-5" rowAmount={1} values={
                        [
                            {title: "Working Days", value: offer.total_shooting_days + " Days "},
                            {title: "Rate", value: translatedRateAmount(offer.rate)},
                            {title: "Kit Rental", value: translatedKitFee(offer.rate)},
                        ]}/>

                    <Content title="Production Details" className="pt-5 gap-y-3">
                        <Flex>
                            <Text type="xs-medium" color="denim-50">Title</Text>
                            <FlexRow align="between">
                                <Text type="sm-medium">{offer.production_name}</Text>
                                <TouchableOpacity onPress={() => refRBSheet3.current.open()}>
                                    <Text type="xs-medium" color="navy-light-100">View Synopsis</Text>
                                </TouchableOpacity>
                            </FlexRow>
                        </Flex>

                        <Flex className="mt-3">
                            <Text type="xs-medium" color="denim-50">Type</Text>
                            <Text type="sm-medium">{offer.production_type}</Text>
                        </Flex>
                    </Content>

                    <Content title="Location & Dates" className="pt-2">
                        <ContentList className="-mt-2" values={offer.cities.map(data => {
                            return [data.city, `${formatDateDefault(data.first_shooting_day)} - ${formatDateDefault(data.last_shooting_day)}`]
                        })}/>
                    </Content>

                    {(offer.policies.travel != undefined ||
                                offer.policies.expense != undefined ||
                                offer.policies.bonus != undefined) &&
                            <Content title="Crew Policies">
                                {offer.policies.travel != undefined && <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20 -mt-3">
                                    <Text type="sm-medium" color="denim-100">Travel Compensation</Text>
                                    <TouchableOpacity onPress={() => {
                                        setViewDetailsTitle("Travel Compensation");
                                        setViewDetailsContent(offer.policies.travel);
                                        refRBSheet4.current.open();
                                    }}>
                                        <Text type="xs-medium" color="navy-light-100">View</Text>
                                    </TouchableOpacity>
                                </FlexRow>}

                                {offer.policies.expense != undefined && <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20">
                                    <Text type="sm-medium" color="denim-100">Expense</Text>
                                    <TouchableOpacity onPress={() => {
                                        setViewDetailsTitle("Expense");
                                        setViewDetailsContent(offer.policies.expense);
                                        refRBSheet4.current.open();
                                    }}>
                                        <Text type="xs-medium" color="navy-light-100">View</Text>
                                    </TouchableOpacity>
                                </FlexRow>}

                                {offer.policies.bonus != undefined && <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20">
                                    <Text type="sm-medium" color="denim-100">Reimbursement</Text>
                                    <TouchableOpacity onPress={() => {
                                        setViewDetailsTitle("Reimbursement");
                                        setViewDetailsContent(offer.policies.bonus);
                                        refRBSheet4.current.open();
                                    }}>
                                        <Text type="xs-medium" color="navy-light-100">View</Text>
                                    </TouchableOpacity>
                                </FlexRow>}
                            </Content>}
                </Flex>
            </ScrollView>

            {status == "active" || status == null &&
                <FloatingBottom>
                    <SecondaryButton value="Decline Offer" onPress={() => refRBSheet.current.open()}/>
                    <Button useFullColor={true} value="Accept Offer & Proceed" className="mt-2" onPress={() => {
                        if (offer.employment == "w2_loan_out") {
                            refRBSheet2.current.open();
                            return;
                        }

                        acceptOffer();
                    }}/>
                </FloatingBottom>
            }

            <RBSheet
                ref={refRBSheet}
                animationType={"fade"}
                height={600}
                customStyles={{
                    container: {
                        borderTopLeftRadius: 28,
                        borderTopRightRadius: 28,
                        width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                        marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                    },
                }}
            >
                <Flex className="p-8 h-full" verticalAlign="between">
                    <Flex align="start">
                        <Flex className="w-full">
                            <Text type="sm-semibold" className="mb-2" color="coral-100">Are you sure you want to decline?</Text>
                            <Text type="xs-medium" className="mb-2" color="denim-100">You can opt to decline the current offer but let the company know you’re willing to negotiate - this does not guarantee that you will be
                                re-offered the role.</Text>
                        </Flex>

                        <Flex className="w-full gap-y-2 divide-y divide-denim-20 pt-8">
                            <TouchableOpacity onPress={() => setDeclineType(0)}>
                                <FlexRow className="p-2">
                                    {declineType == 0 && <CheckRounded width={16} height={16} fill={transformTailwindToHex("coral-100")}/>}
                                    {declineType == 1 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}

                                    <Flex className="ml-2"><Text type="sm-semibold" color={declineType == 0 ? "coral-100" : "denim-50"}>Decline Offer (default)</Text></Flex>
                                </FlexRow>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setDeclineType(1)}>
                                <FlexRow className="p-2 py-3">
                                    {declineType == 1 && <Flex><CheckRounded width={16} height={16} fill={transformTailwindToHex("coral-100")}/></Flex>}
                                    {declineType == 0 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}

                                    <Flex className="ml-2"><Text type="sm-semibold" color={declineType == 1 ? "coral-100" : "denim-50"}>Decline but Willing to Negotiate</Text></Flex>
                                </FlexRow>
                            </TouchableOpacity>
                        </Flex>
                    </Flex>
                    <Flex className="gap-y-2">
                        <SecondaryButton value="Back" onPress={() => refRBSheet.current.close()}/>
                        <Button value="Confirm Selection & Decline Offer" onPress={() => rejectOffer()} color="coral-100" useFullColor={true} loading={loading}/>
                    </Flex>
                </Flex>
            </RBSheet>

            <RBSheet
                    ref={refRBSheet2}
                    animationType={"fade"}
                    height={600}
                    customStyles={{
                        container: {
                            borderTopLeftRadius: 28,
                            borderTopRightRadius: 28,
                            width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                            marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                        },
                    }}
                >
                    <Flex className="p-8 h-full" verticalAlign="between">
                        <Flex className="gap-y-8" align="start">
                            <Flex>
                                <Text type="sm-semibold" className="mb-2" color="navy-light-100">Confirm Employment Type</Text>
                                <Text type="xs-medium" color="denim-100/60">This role is being offered as a {offer.employment == "w2" ? "W-2" : "Contractor"}, however if you would prefer to work as
                                    a {offer.employment == "w2" ? "Loan-Out" : "W-2"} instead please select it from the options below:</Text>
                            </Flex>

                            <Flex className="w-full space-y-2 divide-y divide-denim-20">
                                <TouchableOpacity onPress={() => setAcceptType(0)}>
                                    <FlexRow className={`p-2 py-3 rounded-lg`}>
                                        {acceptType == 0 && <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                        {acceptType == 1 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}

                                        <Text type="xs-semibold" className="ml-2" color={acceptType == 0 ? "navy-light-100" : "denim-50"}>Continue as {offer.employment == "w2" ? "W-2 Crew" : "Contractor"} (default)</Text>
                                    </FlexRow>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => setAcceptType(1)}>
                                    <FlexRow className={`p-2 py-3 rounded-lg`}>
                                        {acceptType == 1 && <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                        {acceptType == 0 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}

                                        <Text type="xs-semibold" className="ml-2" color={acceptType == 1 ? "navy-light-100" : "denim-50"}>Continue as Loan-Out Crew</Text>
                                    </FlexRow>
                                </TouchableOpacity>
                            </Flex>
                        </Flex>
                        <Flex className="gap-y-2">
                            <SecondaryButton value="Back" onPress={() => refRBSheet2.current.close()}/>
                            <Button value="Confirm Selection & Continue" onPress={() => {
                                refRBSheet2.current.close()
                                refRBSheet5.current.open()
                            }} color="navy-light-100" useFullColor={true} loading={loading} className="w-full"/>
                        </Flex>
                    </Flex>
                </RBSheet>

            <RBSheet
                ref={refRBSheet3}
                animationType={"fade"}
                height={600}
                customStyles={{
                    container: {
                        borderTopLeftRadius: 28,
                        borderTopRightRadius: 28,
                        width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                        marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                    },
                }}
            >
                <Flex className="p-8 h-full gap-y-2" verticalAlign="between">
                    <Flex align="start">
                        <Text type="sm-semibold" color="navy-light-100" className="mb-2">Production Synopsis</Text>
                        <Text type="xs-medium" color="denim-100/60">{offer.production_synopsis}</Text>
                    </Flex>
                    <SecondaryButton value="Close" onPress={() => refRBSheet3.current.close()}/>
                </Flex>
            </RBSheet>

            <RBSheet
                ref={refRBSheet4}
                animationType={"fade"}
                height={600}
                customStyles={{
                    container: {
                        borderTopLeftRadius: 28,
                        borderTopRightRadius: 28,
                        width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                        marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                    },
                }}
            >
                <Flex className="p-8 h-full gap-y-2" verticalAlign="between">
                    <Flex align="start">
                        <Text type="sm-semibold" color="navy-light-100" className="mb-2">{viewDetailsTitle}</Text>
                        <Text type="xs-medium" color="denim-100/60">{viewDetailsContent}</Text>
                    </Flex>
                    <SecondaryButton value="Close" onPress={() => refRBSheet4.current.close()}/>
                </Flex>
            </RBSheet>

            <RBSheet
                    ref={refRBSheet5}
                    animationType={"fade"}
                    height={600}
                    customStyles={{
                        container: {
                            borderTopLeftRadius: 28,
                            borderTopRightRadius: 28,
                            width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                            marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                        },
                    }}
                >
                    <Flex className="p-8 h-full" verticalAlign="between">
                        <Flex className="gap-y-8" align="start">
                            <Flex>
                                <Text type="sm-semibold" className="mb-2" color="navy-light-100">Confirm Payment Preference</Text>
                                <Text type="xs-medium" color="denim-100/60">Choose how you would like to receive payments from FilmUp. You can always change this later in Settings.</Text>
                            </Flex>

                            <Flex className="w-full space-y-2 divide-y divide-denim-20">
                                <TouchableOpacity onPress={() => setDirectDeposit(1)}>
                                    <FlexRow className={`p-2 py-3 rounded-lg`}>
                                        {directDeposit == 1 && <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                        {directDeposit == 0 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}

                                        <Text type="xs-semibold" className="ml-2" color={directDeposit == 1 ? "navy-light-100" : "denim-50"}>Direct Deposit (default)</Text>
                                    </FlexRow>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => setDirectDeposit(0)}>
                                    <FlexRow className={`p-2 py-3 rounded-lg`}>
                                        {directDeposit == 0 && <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                        {directDeposit == 1 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}

                                        <Text type="xs-semibold" className="ml-2" color={directDeposit == 0 ? "navy-light-100" : "denim-50"}>Physical Check</Text>
                                    </FlexRow>
                                </TouchableOpacity>
                            </Flex>
                        </Flex>
                        <Flex className="gap-y-2">
                            <SecondaryButton value="Back" onPress={() => refRBSheet5.current.close()}/>
                            <Button value="Confirm Selection & Continue" onPress={() => acceptOffer()} color="navy-light-100" useFullColor={true} loading={loading} className="w-full"/>
                        </Flex>
                    </Flex>
                </RBSheet>
        </Flex>
    );
}