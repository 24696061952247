import moment from "moment/moment";
import {rateInfoType} from "@components/General/Rates";

export const formatDate = (date: string | null | undefined, format: string, parse: boolean = true) => {
    if (parse) {
        return date != null ? moment.parseZone(date).format(format) : "-";
    }

    return date != null ? moment(date).format(format) : "-";
}

export const formatDateDefault = (date: string | null | undefined, parse: boolean = true) => {
    return formatDate(date, "MMM DD", parse);
}

export const formatDateDefaultYear = (date: string | null | undefined, parse: boolean = true) => {
    return formatDate(date, "MMM DD, YYYY", parse);
}

export const formatDateDefaultYearDayTypes = (date: string | null | undefined, parse: boolean = true) => {
    return formatDate(date, "dddd, MMM DD, YYYY", parse);
}

export const formatDateLongDayMonth = (date: string | null | undefined, parse: boolean = true) => {
    return formatDate(date, "dddd, MMM DD", parse);
}

export const formatDateDefaultTime = (date: string | null | undefined, parse: boolean = true) => {
    return formatDate(date, "h:mm A", parse);
}

export const formatTimecardtime = (date: string | null | undefined, parse: boolean = true) => {
    return formatDate(date, "hh:mm a", parse).replace("m", "");
}

export const formatSuggestedTimecard = (date: string | null | undefined, parse: boolean = true) => {
    return formatDate(date, "HH:mm", parse);
}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const msToHMS = (ms) => {

    if (ms < 0) {
        ms = ms * -1;
    }

    var seconds = ms / 1000;

    var hours = parseInt(seconds / 3600);
    seconds = parseInt(seconds % 3600);

    var minutes = parseInt(seconds / 60);

    seconds = parseInt(seconds % 60);

    return minTwoDigits(hours) + ":" + minTwoDigits(minutes) + ":" + minTwoDigits(seconds)
}

function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
}

export const transformTailwindToHex = (color: string) => {
    const colors: Record<string, string> = {
        'navy-light-100': '#0062EC',
        'navy-light-100/40': '#052E9C40',
        'denim-100': '#131E3C',
        'denim-50': '#898E9D',
        'denim-20': '#D0D2D8',
        'sky-100': '#00B0F0',
        'lemon-100': '#FAF469',
        'emerald-100': '#3ABD54',
        'avocado-100': '#50FA72',
        "coral-100": "#F9506A",
        "grape-100": "#7030A0",
        "gray-50/50": "white"
    }

    return colors[color] ?? '#131E3C';
}

export const getDatesBetweenDates = (startDate: string, endDate: string) => {
        let currDate = moment(startDate).startOf('day');
        let lastDate = moment(endDate).startOf('day');

        let dates = [currDate.clone().toDate()];

        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(currDate.clone().toDate());
        }

        return dates;
};

export const getHourDiffBetweenDates = (startDate: string, endDate: string) => {
    let start = moment(startDate);
    let end = moment(endDate);

    let diff = end.diff(start, 'hours');
    return isNaN(diff) ? null : diff;
}

export const getWorkingTotalHoursForTimecardDay = (startDate: string, endDate: string, meal1: object|null, meal2: object|null) => {

    if (startDate == null || endDate == null) {
        return null;
    }

    let start = moment(startDate);
    let end = moment(endDate);
    let diff = end.diff(start, 'minutes')
    diff = isNaN(diff) ? 0 : diff;
    let meal1_diff = 0;
    let meal2_diff = 0;

    if (meal1?.start_time != null && meal1?.end_time != null) {
        let meal1_start = moment(meal1.start_time);
        let meal1_end = moment(meal1.end_time);
        meal1_diff = meal1_end.diff(meal1_start, 'minutes');
        meal1_diff = isNaN(meal1_diff) ? 0 : meal1_diff;
    }

    if (meal2?.start_time != null && meal2?.end_time != null) {
        let meal2_start = moment(meal2.start_time);
        let meal2_end = moment(meal2.end_time);
        meal2_diff = meal2_end.diff(meal2_start, 'minutes');
        meal2_diff = isNaN(meal2_diff) ? 0 : meal2_diff;
    }

    let total = (diff - (meal1_diff + meal2_diff))/60;

    return total == 0 ? null : Math.round(total * 10) / 10;
}

export const translatedRateAmount = (rate: rateInfoType) => {

    const type = rate.type == "production" ? "project" : rate.type;
    let guaranteedString = "";

    if (rate.guaranteed_hours != null && rate.guaranteed_hours > 0) {
        guaranteedString = `(${rate.guaranteed_hours}h gtd.)`
    }

    switch (type) {
        case "hour":
            return `${translatedAmount(rate.amount)} / hr ${guaranteedString}`;

        case "hour-day":
            return `${translatedAmount(rate.amount)} / ${rate.hours}h Day ${guaranteedString}`;

        case "day":
        case "week":
        case "project":
            return `${translatedAmount(rate.amount)} / ${capitalizeFirstLetter(type)}`;
    }
}

export function translatedKitFee(rate) {
    const kit_fee = rate.kit_fee;

    if (kit_fee == null) {
        return "N/A"
    }

    return `${translatedAmount(kit_fee.amount)} / ${capitalizeFirstLetter(kit_fee.type)}`;
}

export function translatedAmount(amount: number) {
    return `$${(Math.round(amount * 100) / 100).toFixed(2)}`;
}

export function normalizeStatusString(status: string) {
    return capitalizeFirstLetter(status.replace(/_/g, " "));
}

export function toTwoDecimal(value: number) {
    if (value == null) {
        return null;
    }

    return value.toFixed(2);
}

export function isEmpty(value: any) {
    return value == null || value == "" || value == undefined || value == 0 || value.length == 0;
}

export function validateTime(time: string|null, timeType: string): string | null {

    if (time == null || time == ":") {
        return null;
    }

    let timeArr = time.split(":");
    let hours = parseInt(timeArr[0]);
    let minutes = !isEmpty(timeArr[1]) ? parseInt(timeArr[1]) : 0;

    if (hours > 23) {
        return null;
    }

    if (parseInt(timeArr[1]) > 59) {
        return null;
    }

    if (timeType === "am") {
        if (hours > 11) {
            hours = hours - 12
        }
    }

    if (timeType === "pm") {
        if (hours < 12) {
            hours = hours + 12
        }
    }

    return `${addZeroToTime(hours)}:${addZeroToTime(minutes)}`
}

export function validateMealTimeSetCorrectly(timeOut: string, timeIn: string): boolean {
    if (timeOut == null || timeIn == null) {
        return false;
    }

    let timeOutArr = timeOut.split(":");
    let timeInArr = timeIn.split(":");

    let timeOutHours = parseInt(timeOutArr[0]);
    let timeOutMinutes = parseInt(timeOutArr[1]);

    let timeInHours = parseInt(timeInArr[0]);
    let timeInMinutes = parseInt(timeInArr[1]);

    if (timeOutHours > timeInHours) {
        return false;
    }

    if (timeOutHours == timeInHours && timeOutMinutes >= timeInMinutes) {
        return false;
    }

    return true;

}

function addZeroToTime(time: number) {
    return time < 10 ? "0" + time : time;
}