import React, {useState} from "react";

import {StackScreenProps} from "@react-navigation/stack";
import {AuthStackParamList} from "@src/routes";

import Flex from "@components/Views/Flex";
import Header from "@components/Views/Header";
import Text from "@components/General/Text";


import SignDocs from "@components/Views/DocumentUpload/SignDocs";
import IdVerification from "@components/Views/DocumentUpload/IdVerification";
import UploadDocs from "@components/Views/DocumentUpload/UploadDocs";
import ReviewDocs from "@components/Views/DocumentUpload/ReviewDocs";
import FlexRow from "@components/Views/FlexRow";

const headerContent = {
    sign: {
        title: "Sign Documents",
        subtitle: "Please complete and sign the following documents, they have been pre-filled with your information.",
        step: "1 of 3"
    },
    verification: {
        title: "Select ID Verification",
        subtitle: "Choose which documents you’d like to use to verify your identity from either of the options below.",
        step: "2 of 3"
    },
    upload: {
        title: "Upload Required Documents",
        subtitle: "Please upload all of the following documents and complete all related fields to continue.",
        step: "3 of 3"
    },
    review: {
        title: "Final Review",
        subtitle: "Please review and confirm that all of the documents and materials below are correct before submitting.",
        step: null
    },
}

type OnboardingProps = StackScreenProps<AuthStackParamList, 'OfferOnboarding'>;
export default function Onboarding({navigation, route}: OnboardingProps) {

    const offerId = route.params.offerId;
    const productionId = route.params.productionId;
    const employment = route.params.employment;

    const [stage, setStage] = useState<"sign" | "verification" | "upload" | "review">("sign");
    const [prevStage, setPrevStage] = useState<"sign" | "verification" | "upload" | "review">("sign");
    const [documentsToVerify, setDocumentsToVerify] = useState<object>({});
    const [documentsUploadedCache, setDocumentsUploadedCache] = useState<object[] | null>(null);

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Header title="Production Onboarding"/>

            <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
                <FlexRow align="between">
                    <Text type="sm-semibold" color="white">{headerContent[stage].title}</Text>
                    {employment == "w2" && <Text type="xs-semibold" color="white">{headerContent[stage].step}</Text>}
                </FlexRow>
                <Text type="xs-normal" className="opacity-70 mt-2" color="white">{headerContent[stage].subtitle}</Text>
            </Flex>

            <Flex className="flex-1 bg-white" verticalAlign="between">
                {stage == "sign" && <SignDocs offerId={offerId} nextStep={(docsToUpload) => {
                    setPrevStage("sign")
                    if (employment == "w2") {
                        setStage("verification")
                    } else if (docsToUpload > 0) {
                        setStage("upload")
                    } else {
                        setStage("review")
                    }
                }}/>}
                {stage == "verification" && <IdVerification offerId={offerId} nextStep={(docs: object[]) => {
                    setPrevStage("verification")
                    setDocumentsToVerify(docs)
                    setStage("upload")
                }}/>}
                {stage == "upload" && <UploadDocs offerId={offerId} documentsToVerify={documentsToVerify} nextStep={(documentsCache) => {
                    setPrevStage("upload")
                    setStage("review")
                    setDocumentsUploadedCache(documentsCache)
                }} documentsCache={documentsUploadedCache} backStep={() => {
                    setPrevStage("upload")
                    if (employment == "w2") {
                        setStage("verification")
                    } else {
                        setStage("sign")
                    }
                }} prevStage={prevStage}/>}
                {stage == "review" && <ReviewDocs offerId={offerId} changeStep={(step) => {
                    setPrevStage("review")
                    setStage(step)
                }} finishDocs={() => navigation.navigate("OfferSubmissionComplete", {productionId: productionId})} employment={employment}/>}
            </Flex>
        </Flex>
    );
}
